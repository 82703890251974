<template>
  <v-card rounded="lg" color="grey lighten-5" flat :loading="working" class="flex" min-height="200">
    <v-row align="center" class="pa-3" no-gutters style="font-weight: bold; font-size: 14px"
      >Patient Tags<v-spacer />
      <v-btn small depressed :disabled="!updatesMade" dark color="green" @click="submit()"> Save Updates </v-btn></v-row
    >
    <v-row class="px-3 pb-3" no-gutters>
      <v-text-field
        dense
        class="mb-2"
        ref="tagBox"
        v-model="newTag"
        solo
        flat
        hide-details
        placeholder="Create new tag"
        @keyup.enter.stop.prevent="handleTagClick({ text: newTag.replace(',', ''), partial: false, full: false })"
        @keyup.188.stop.prevent="handleTagClick({ text: newTag.replace(',', ''), partial: false, full: false })"
        @blur="handleTagClick({ text: newTag.replace(',', ''), partial: false, full: false })"
        prepend-inner-icon="mdi-tag-plus-outline"
        color="primary"
        background-color="white"
        outlined
      >
        <v-icon class="mr-1" small color="primary" slot="prepend-inner">mdi-tag-plus-outline</v-icon>
      </v-text-field>
    </v-row>
    <v-row class="px-3" no-gutters>
      <v-chip
        class="mr-2 mb-2"
        label
        v-for="(tag, index) in tagCloud"
        :key="index + tag"
        :color="tag.color"
        @click="handleTagClick(tag)"
      >
        {{ tag.text }}
      </v-chip>
    </v-row>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import HelperMixin from '@/core/mixins/HelperMixin'
import bus from '@/core/helpers/bus'
//import get from 'lodash/get'

export default {
  name: 'PatientTagCloud',
  mixins: [HelperMixin],
  components: {},
  data() {
    return {
      newTag: null,
      saveOnSubmit: false,
      updatesMade: false,
      working: false,
    }
  },
  methods: {
    ...mapActions('patient', ['updateTags']),

    handleTagClick(tag) {
      if (tag.full) {
        // go and give every patient the tag
        this.selectedPatients.forEach(patient => {
          if (patient.tags.includes(tag.text)) {
            let index = patient.tags.indexOf(tag.text)
            if (index > -1) {
              patient.tags.splice(index, 1)
            }
          }
        })
      } else {
        // go and give every patient the tag
        this.selectedPatients.forEach(patient => {
          if (!patient.tags.includes(tag.text)) {
            patient.tags.push(tag.text)
          }
        })
      }

      this.newTag = null

      this.selectedPatients.forEach(patient => {
        this.updateTags(patient.tags).catch(error => {
          console.log(error)
          bus.$emit('toast', { type: 'error', text: 'Error Tagging Chart' })
        })
      })
    },
  },
  computed: {
    ...mapState('org', ['enrolledPatients']),
    ...mapState('patient', ['patient']),
    selectedPatients() {
      return [this.enrolledPatients.find(patient => patient.id === this.patient.id)]
    },
    patientTags() {
      let arrayOfArrayOfTags = this.enrolledPatients.map(a => a.tags)

      let tags = []
      arrayOfArrayOfTags.forEach(patientTags => {
        patientTags.forEach(tag => {
          if (!tags.includes(tag)) {
            tags.push(tag)
          }
        })
      })
      return tags
    },
    tagCloud() {
      // generate a list that has three settings for each tag. 1: everybody has that tag, blue 2: some people have the tag, outlined 3: nobody has the tag. gray
      let tagsToReturn = []
      this.patientTags.forEach(tag => {
        let tagToBeAdded = { text: tag, partial: false, full: false, color: 'grey lighten-4' }
        let patientCountForTagChecking = 0
        this.selectedPatients.forEach(patient => {
          if (patient.tags.includes(tag)) {
            tagToBeAdded.partial = true
            patientCountForTagChecking = patientCountForTagChecking + 1
            tagToBeAdded.color = 'primary lighten-2'
          }
        })
        if (patientCountForTagChecking === this.selectedPatients.length) {
          tagToBeAdded.partial = false
          tagToBeAdded.full = true
          tagToBeAdded.color = 'primary'
        }

        tagsToReturn.push(tagToBeAdded)
      })
      return tagsToReturn
    },
  },
  mounted() {},
}
</script>
