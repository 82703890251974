<template>
  <v-card v-if="patientInfoForEditing" rounded="lg" flat color="grey lighten-5" class="flex mb-4 pb-4 px-3">
    <v-textarea
      outlined
      label="Additional patient information"
      rows="4"
      @change="edited = true"
      @blur="savePatientInfo"
      auto-grow
      no-resize
      flat
      solo
      v-model="patientInfoForEditing.generalNotes"
      background-color="grey lighten-5"
      hide-details
    >
    </v-textarea>
    <v-row v-if="false" class="px-4 pt-1" align="center" no-gutters>
      <v-spacer /><span class="mr-1 text-body-2" v-if="usersDict[patientInfoForEditing.lastAuthor]"
        >Updated: {{ usersDict[patientInfoForEditing.lastAuthor].firstName }}
        {{ usersDict[patientInfoForEditing.lastAuthor].lastName }}</span
      >
      <span class="text-body-2"
        >{{ specificRelativeTimeWithHoursAndMinutes(Date.parse(patientInfoForEditing.updated)) }}
      </span>
    </v-row>
  </v-card>
</template>
<script>
import { defineComponent, onMounted, ref, computed, onBeforeUnmount } from '@vue/composition-api'
import { specificRelativeTimeWithHoursAndMinutes } from '@/helpers/time'

export default defineComponent({
  props: {
    patient: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, context) {
    const rootStore = context.root.$store // TODO: move our modules from vuex to composition api
    const patientInfo = ref(props.patient.generalNotes)
    const edited = ref(false)

    const usersDict = ref(rootStore.state.org.usersDict)

    const autoGeneratedPatientInfoContent = computed(() => {
      return {
        generalNotes: '',
        lastRevised: '',
        lastAuthor: '',
        editLog: [],
      }
    })

    const patientInfoForEditing = ref(null)

    const savePatientInfo = async () => {
      if (edited.value) {
        patientInfoForEditing.value.updated = new Date().toISOString()
        patientInfoForEditing.value.lastAuthor = rootStore.state.auth.user.id
        patientInfoForEditing.value.editLog.push({
          author: rootStore.state.auth.user.id,
          revision: patientInfoForEditing.value.updated,
        })
        await rootStore.dispatch('patient/updateGeneralNotes', {
          notes: JSON.stringify(patientInfoForEditing.value),
        })
      }
    }

    onMounted(() => {
      if (props.patient.generalNotes) {
        patientInfoForEditing.value = JSON.parse(props.patient.generalNotes)
      } else {
        patientInfoForEditing.value = JSON.parse(JSON.stringify(autoGeneratedPatientInfoContent.value))
      }
    })

    onBeforeUnmount(() => {
      savePatientInfo()
    })

    return {
      patientInfo,
      autoGeneratedPatientInfoContent,
      patientInfoForEditing,
      edited,
      specificRelativeTimeWithHoursAndMinutes,
      usersDict,
      savePatientInfo,
    }
  },
})
</script>

<style>
.card.left {
  -webkit-transform: scale(0.35);
  -moz-transform: scale(0.35);
  -o-transform: scale(0.35);
  -ms-transform: scale(0.35);
  transform: scale(0.35) translate(-230px, -150px);
  z-index: 0;
}
.card {
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
  -o-transition: 0.3s linear;
  transition: 0.3s linear;
  width: 100%;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1) translate(0px, 30px);
  z-index: 10;
}
.card.right {
  -webkit-transform: scale(0.35);
  -moz-transform: scale(0.35);
  -o-transform: scale(0.35);
  -ms-transform: scale(0.35);
  transform: scale(0.35) translate(230px, -150px);
  z-index: 0;
}
</style>
