<template>
  <v-layout class="patient-page" justify-start>
    <v-flex xs12>
      <PatientDemographicsPanel class="mb-4" />
      <patient-tag-cloud class="mb-4" />
      <alert-rule-settings class="mb-4" />

      <patient-notifications class="mb-4" />
      <chart-access-log />
    </v-flex>
  </v-layout>
</template>

<script>
import HelperMixin from '@/core/mixins/HelperMixin'
import AlertRuleSettings from '@/components/AlertRuleSettings'
import PatientDemographicsPanel from '@/components/PatientDemographicsPanel'
import PatientTagCloud from '@/components/PatientTagCloud'
import PatientNotifications from '@/components/PatientNotifications'
import ChartAccessLog from '@/components/ChartAccessLog'

export default {
  mixins: [HelperMixin],
  components: {
    ChartAccessLog,
    AlertRuleSettings,
    PatientDemographicsPanel,
    PatientNotifications,
    PatientTagCloud,
  }
}
</script>