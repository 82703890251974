<template>
  <v-card :loading="patientUpdating" outlined flat tile>
    <add-alert-recipient @addEmail="addRecipient" ref="addAlertNotificationRecipient" />
    <v-toolbar flat>
      <v-toolbar-title>Patient Alert Notifications</v-toolbar-title>
      <v-spacer />
      <v-btn color="green" dark depressed @click="addThreshold">
        <v-icon class="mr-2" left>mdi-email-plus</v-icon>Add Recipient
      </v-btn>
    </v-toolbar>
    <v-card-text class="pt-0">
      Patient alert notifications are sent by email whenever a threshold is breached. The value that breached the
      threshold is included in the body of the email along with a link to the chart. No identifiable patient information
      is sent in the email. Please ensure that a recipient has access to the patient's chart before they are added to
      the list below.
    </v-card-text>
    <v-divider></v-divider>

    <v-simple-table style="width: 100%;">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Recipient</th>
            <th class="text-left">Remove</th>
          </tr>
        </thead>
        <tbody v-if="patient.rpm.alertNotifications.length > 0">
          <tr v-for="(item, index) in patient.rpm.alertNotifications" :key="index" :class="alternatingColors(index)">
            <td class="mt-2 mb-2">{{ item.email }}</td>
            <td style="max-width: 80px; width: 80px;">
              <v-layout row justify-center>
                <v-btn @click="removeRecipient(item)" color="red" icon>
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-layout>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-layout
      class="mx-0 mt-4 mb-4"
      v-if="patient.rpm.alertNotifications.length === 0"
      style="min-width: 100%; opacity: 0.4"
      row
      justify-center
      >
      No Recipients Set
    </v-layout>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import AddAlertRecipient from '@/components/dialogs/AddAlertRecipient'
import HelperMixin from '@/core/mixins/HelperMixin'

export default {
  mixins: [HelperMixin],
  components: {
    AddAlertRecipient,
  },
  computed: {
    ...mapState('patient', ['patient', 'patientUpdating']),
  },
  methods: {
    ...mapActions('patient', ['update']),
    addThreshold() {
      this.$refs.addAlertNotificationRecipient.open()
    },
    removeRecipient(item) {
      const index = this.patient.rpm.alertNotifications.indexOf(item)
      if (index > -1) this.patient.rpm.alertNotifications.splice(index, 1)
      this.update({ payload: { rpmAlertNotifications: { value: this.patient.rpm.alertNotifications } } })
    },
    addRecipient(email) {
      this.patient.rpm.alertNotifications.push({ email: email })
      this.update({ payload: { rpmAlertNotifications: { value: this.patient.rpm.alertNotifications } } })
    },
  }
}
</script>
