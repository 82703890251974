<template>
  <v-card rounded="lg" color="grey lighten-5" flat :loading="working" class="flex" min-height="200">
    <v-row align="center" class="pa-3" no-gutters style="font-weight: bold; font-size: 14px"
      >Patient Demographics<v-spacer />
      <v-btn small depressed :disabled="!updatesMade" dark color="green" @click="submit()"> Save Updates </v-btn></v-row
    >
    <v-form ref="patientDemographics">
      <v-row class="px-2" no-gutters>
        <v-col cols="12">
          <v-row no-gutters>
            <v-text-field
              class="ma-1"
              outlined
              v-model="patientInput.firstName"
              :rules="nameRules"
              label="First Name"
              required
              dense
              @keyup="updatesMade = true"
            ></v-text-field>
            <v-text-field
              class="ma-1"
              outlined
              v-model="patientInput.lastName"
              :rules="nameRules"
              label="Last Name"
              required
              dense
              @keyup="updatesMade = true"
            ></v-text-field>
          </v-row>
          <v-row no-gutters>
            <v-col xs="6" sm="6" md="5" lg="4">
              <v-select
                class="ma-1"
                outlined
                v-model="patientInput.gender"
                :rules="genderRules"
                :items="genderOptions"
                label="Sex"
                required
                dense
                @change="updatesMade = true"
              ></v-select>
            </v-col>
            <v-col xs="6" sm="6" md="5" lg="4">
              <v-text-field
                class="ma-1"
                outlined
                v-mask="'##/##/####'"
                :rules="birthdateRules"
                v-model="patientInput.birthdate"
                label="Date of Birth"
                required
                dense
                hint="mm/dd/yyyy"
                persistent-hint
                @keyup="updatesMade = true"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-text-field
              class="ma-1"
              outlined
              v-model="patientInput.email"
              label="Patient Email"
              dense
              @keyup="updatesMade = true"
            ></v-text-field>
          </v-row>
          <v-row no-gutters>
            <v-col xs="12" md="7" lg="6">
              <v-text-field
                class="ma-1"
                outlined
                v-model="patientInput.primaryPhone"
                label="Primary Phone"
                v-mask="'###-###-####'"
                dense
                @keyup="updatesMade = true"
                persistent-hint
              >
              </v-text-field>
            </v-col>
            <v-col xs="12" md="7" lg="6">
              <v-text-field
                class="ma-1"
                outlined
                v-model="patientInput.smsPhone"
                label="Phone for Messaging"
                v-mask="'###-###-####'"
                dense
                @keyup="updatesMade = true"
                hint="This number used for messaging"
                persistent-hint
              >
                <v-icon color="primary" small slot="append">mdi-message-outline</v-icon>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col xs="12" md="7" lg="6">
              <v-text-field
                disabled
                dense
                class="ma-1"
                outlined
                readonly
                v-model="patient.org.patientId"
                label="Organization Patient ID"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col>
          <v-row no-gutters>
            <v-text-field
              class="ma-1"
              outlined
              v-model="patientInput.address.line1"
              :rules="patientIDRules"
              label="Street Address"
              required
              dense
              @keyup="updatesMade = true"
            ></v-text-field>
          </v-row>
          <v-row no-gutters>
            <v-text-field
              class="ma-1"
              outlined
              v-model="patientInput.address.line2"
              label="Street Address Line 2"
              dense
              @keyup="updatesMade = true"
            ></v-text-field>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <v-text-field
                class="ma-1"
                outlined
                :rules="patientIDRules"
                v-model="patientInput.address.city"
                label="City"
                required
                dense
                @keyup="updatesMade = true"
              ></v-text-field>
            </v-col>
            <v-col xs="6" sm="6" md="5" lg="4">
              <v-select
                class="ma-1"
                outlined
                :rules="birthdateRules"
                v-model="patientInput.address.state"
                label="State"
                :items="stateList"
                required
                dense
                @change="updatesMade = true"
              ></v-select>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col xs="12" sm="8" md="5" lg="4">
              <v-text-field
                class="ma-1"
                outlined
                :rules="patientIDRules"
                v-model="patientInput.address.zip"
                v-mask="'#####'"
                label="Zip"
                required
                dense
                @keyup="updatesMade = true"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
    <patient-demographics-general-notes :patient="patient" />
  </v-card>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import HelperMixin from '@/core/mixins/HelperMixin'
import bus from '@/core/helpers/bus'
import { mask } from 'vue-the-mask'
import clone from 'lodash/clone'
import PatientDemographicsGeneralNotes from '@/components/PatientDemographicsGeneralNotes.vue'

export default {
  name: 'ChartCreationDialog',
  mixins: [HelperMixin],
  components: { PatientDemographicsGeneralNotes },
  directives: {
    mask,
  },
  data() {
    return {
      updatesMade: false,
      working: false,
      genderOptions: ['Male', 'Female'],
      patientInput: {
        firstName: '',
        lastName: '',
        gender: '',
        birthdate: '',
        address: {
          line1: '',
          line2: '',
          city: '',
          state: '',
          zip: '',
        },
        email: '',
        smsPhone: '',
        primaryPhone: '',
      },
      nameRules: [v => !!v || 'Name is required'],
      birthdateRules: [v => !!v || 'Birthdate is required'],
      genderRules: [v => !!v || 'Sex is required'],
      patientIDRules: [v => !!v || 'Required'],
      possibleDuplicates: [],
    }
  },
  methods: {
    ...mapActions('patient', ['update']),
    ...mapMutations('patient', ['setPatient']),
    showPossibleMatches() {
      this.$refs.possibleMatchesDialog.open({
        possibleMatches: this.possibleDuplicates,
      })
    },
    updated() {
      this.updatesMade = true
    },
    rfc3339ToMMDDYYYY(d) {
      // ISO to mm/dd/yyyy
      return `${d.substring(5, 7)}/${d.substring(8, 10)}/${d.substring(0, 4)}`
    },
    mmddyyyyToRFC3339(d) {
      // mm/dd/yyyy to ISO
      return `${d.substring(6, 10)}-${d.substring(0, 2)}-${d.substring(3, 5)}T00:00:00Z`
    },
    async submit() {
      if (!this.$refs.patientDemographics.validate()) return
      // patch the patient.
      const p = clone(this.patientInput)
      p.birthdate = p.birthdate ? this.mmddyyyyToRFC3339(p.birthdate) : p.birthdate

      // update modified fields
      const payload = {}
      // special handling for address
      const addr = this.patient.address || {}
      const addrModified = Array.from(Object.entries(p.address)).some(([k, v]) => {
        return !v !== !addr[k] || v !== (addr[k] || '')
      })
      if (addrModified) payload.address = p.address
      delete p.address
      // check all other fields
      for (const [k, v] of Object.entries(p)) {
        if (v === this.patient[k] || (!v && !this.patient[k])) continue // no change to this field
        payload[k] = { value: v }
      }

      // no modifications to update
      if (Object.keys(payload).length === 0) {
        this.updatesMade = false
        return
      }

      try {
        await this.update({ payload })
        this.updatesMade = false
        bus.$emit('toast', { type: 'success', text: 'Information Updated' })
      } catch (e) {
        console.error(e)
        bus.$emit('toast', { type: 'error', text: 'Internal Error: ' + e })
      }
    },
    parsePatient() {
      this.patientInput.firstName = this.patient.firstName || ''
      this.patientInput.lastName = this.patient.lastName || ''
      this.patientInput.gender = this.patient.gender || ''
      this.patientInput.birthdate = this.patient.birthdate ? this.rfc3339ToMMDDYYYY(this.patient.birthdate) : ''
      this.patientInput.address = this.patient.address ? clone(this.patient.address) : {}
      this.patientInput.email = this.patient.email || ''
      this.patientInput.smsPhone = this.patient.smsPhone || ''
      this.patientInput.primaryPhone = this.patient.primaryPhone || ''
    },
  },
  watch: {
    patient() {
      this.parsePatient()
    },
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('org', ['org']),
    ...mapState('patient', ['patient']),
  },
  mounted() {
    this.parsePatient()
  },
}
</script>
