<template>
  <v-card :loading="accessLogsLoading" outlined flat>
    <v-toolbar flat color="transparent">
      <v-toolbar-title>
        Chart Access Log
      </v-toolbar-title>
    </v-toolbar>
    <v-divider />
    <v-simple-table height="400" fixed-header style="width: 100%;">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Access</th>
            <th class="text-right">Timestamp</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in accessLogs" :key="index">
            <td class="mt-2 mb-2">
              {{ item.userDisplayName }} <span style="opacity:0.7">{{ item.description }}</span>
            </td>
            <td style="max-width: 200px; width: 200px; text-align: right">{{ item.timestampString }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('patient', ['patient', 'accessLogs', 'accessLogsLoading']),
    ...mapState('auth', ['user']),
  },
}
</script>
